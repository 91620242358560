import { Component } from "react";
import ReactDOM from "react-dom";
import "./index.css";
import * as serviceWorker from "./serviceWorker";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import {
  Navigation,
  Channels
} from "./components";


class App extends Component {

  render() {
    return (
      <Router>
        <Navigation/>

        <Routes>
          <Route exact path="/"  element={<Channels /> } />;
        </Routes>

        {/* <Footer /> */}
      </Router>
    );
  }
}

ReactDOM.render(
  <App />, document.getElementById("root")
);

serviceWorker.unregister();
