export const colors_corpus =[
{"color":"#fff","backgroundcolor":"#5856d6"},
{"color":"#fff","backgroundcolor":"#007AFF"},
{"color":"#fff","backgroundcolor":"#34AADC"},
{"color":"#fff","backgroundcolor":"#5AC8FA"},
{"color":"#000","backgroundcolor":"#4cd964"},
{"color":"#fff","backgroundcolor":"#ff2d55"},
{"color":"#fff","backgroundcolor":"#ff3b30"},
{"color":"#fff","backgroundcolor":"#ff9500"},
{"color":"#000","backgroundcolor":"#ffcc00"},
{"color":"#fff","backgroundcolor":"#8e8e93"},
{"color":"#000","backgroundcolor":"#ceced2"},
{"color":"#000","backgroundcolor":"#efeff4"},
]
