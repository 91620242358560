import { Component } from "react";
import { Container } from "react-bootstrap";
import Channel from "./Channel";
import "./Channels.scss";
import { colors_corpus } from "./colors.js";
import { ProSidebar, Menu, MenuItem, SubMenu } from "react-pro-sidebar";
import { FaBars } from 'react-icons/fa';

// const libs = [
//   {
//     name:"canal+",
//     src:"https://leet365.cc/fr/hd/5",
//   }
// ]

export default class QuickLib extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Libs: [],
    };
    this.onHandleChange = this.onHandleChange.bind(this);
    this.onHandleChangeVideo = this.onHandleChangeVideo.bind(this);

    let chapter = localStorage.getItem("chapter") | 0;
    let video = localStorage.getItem("video") | 0;

    fetch("Tv.json")
      .then((response) =>
        response.json().then((data) => {
          // console.log(chapter,video,  !data[chapter],!data[chapter][video])
          // if(!data[chapter][video] ){
          //   chapter = 0
          //   video = 0
          // }

          this.setState({
            Libs: data,
            Selected_chapter: chapter,
            Selected_video: video,
            chapter_clicked: chapter,
          });
        })
      )
      .catch((error) => console.error(error));
  }

  handleToggleSidebar(collapse){

  }

  onHandleChange(e, clicked_id) {
    // console.log(e, clicked_id);
    let changed = this.state.chapter_clicked === clicked_id;

    this.setState({
      chapter_clicked: clicked_id,
      changed: changed,
    });

    // localStorage.setItem("chapter",clicked_id);
    return;
  }

  onHandleChangeVideo(e, clicked_id) {
    this.setState({
      Selected_video: clicked_id,
      Selected_chapter: this.state.chapter_clicked,
    });
    localStorage.setItem("video", clicked_id);
    localStorage.setItem("chapter", this.state.chapter_clicked);
    return;
  }

  render() {
    // console.log(this.state.Selected)
    // console.log(this.state.Libs)
    let channel;
    if (
      this.state.Selected_chapter !== undefined &&
      this.state.Selected_video !== undefined
    ) {
      channel = (
        <Channel
          chapter={this.state.Libs[this.state.Selected_chapter].name}
          video={{
            name: this.state.Libs[this.state.Selected_chapter].videos[
              this.state.Selected_video
            ].name,
            src: this.state.Libs[this.state.Selected_chapter].videos[
              this.state.Selected_video
            ].video,
            embed:
              this.state.Libs[this.state.Selected_chapter].videos[
                this.state.Selected_video
              ].embed,
          }}
          color={
            colors_corpus[this.state.Selected_chapter % colors_corpus.length]
          }
        />
      );
    }
    return (
      <Container fluid className="libcontainer">
        <div className="btn-toggle" onClick={() => this.handleToggleSidebar(true)}>
        <FaBars />
      </div>
        <ProSidebar className="md"  onToggle={this.handleToggleSidebar}>
          <Menu iconShape="square">
            {this.state.Libs.map((ch, idx) => {
              return (
                <SubMenu
                  key={idx}
                  title={ch.name}
                  onClick={(e) => this.onHandleChange(e, idx)}
                >
                  {ch.videos.map((video, ivd) => {
                    return (
                      <MenuItem
                        key={"K" + idx + "_" + ivd}
                        onClick={(e) => this.onHandleChangeVideo(e, ivd)}
                      >
                        {video.name}
                      </MenuItem>
                    );
                  })}
                </SubMenu>
              );
            })}
          </Menu>
        </ProSidebar>

        {channel}
      </Container>
    );
  }
}
