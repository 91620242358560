import { Component } from "react";
import "./Channels.scss";

export default class Channel extends Component {
  render() {
    // console.log(this.props.video.embed)
    let vid ;
    if(this.props.video.embed){
      vid = <iframe src={this.props.video.embed} title={this.props.video.name} width="100%" height="100%" frameborder="0" allowfullscreen="allowfullscreen"></iframe>

    }else{
      vid = <video controls name="media" className="bg-dark w-100 " src={this.props.video.src} type="video/mp4" ></video>
    }
    
    return (
      <div className="Cardcontainer bordered">
        <div className="card w-100 h-100">
          <div
            className="card-header d-flex"
            style={{
              color: this.props.color.color,
              backgroundColor: this.props.color.backgroundcolor,
              // textAlign: "center",
            }}
          >
            {/* <a href={this.props.tv.src} target="_blank" rel="noopener noreferrer" >{this.props.tv.name}</a> */}
            <h6 className="m-0">{this.props.chapter}</h6> 
            <div className="ml-auto"> {this.props.video.name} </div>
          </div>

          <div className="card-body">
          {vid}
          </div>
        </div>
      </div>
    );
  }
}
